var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"title"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":_vm.borderBool,"cell-style":_vm.setCellStyle,"data":_vm.tableData,"header-cell-style":_vm.headerStyle,"height":_vm.height,"row-key":"id","tree-props":_vm.treeProps},on:{"selection-change":_vm.handleSelectionChange}},[(_vm.select)?_c('el-table-column',{attrs:{"type":"selection","width":"55"}}):_vm._e(),(_vm.serialNumber)?_c('el-table-column',{attrs:{"align":_vm.orientation,"label":"序号","property":"index","type":"index","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s((_vm.currentValue - 1) * 10 + scope.$index + 1))])]}}],null,false,1305659325)}):_vm._e(),_c('el-table-column',{attrs:{"label":"瑕疵状态","align":"center","prop":"handleStatusDesc","width":"150px","min-width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.storeViewStatus == 0)?_c('el-badge',{staticClass:"item",attrs:{"is-dot":""}},[_c('span',[_vm._v(" "+_vm._s(scope.row.handleStatusDesc)+" ")])]):_c('span',[_vm._v(_vm._s(scope.row.handleStatusDesc))])]}}])}),_vm._l((_vm.titleList),function(item){return _c('el-table-column',{key:item.prop,attrs:{"align":_vm.orientation,"label":item.label,"prop":item.prop,"width":item.width,"show-overflow-tooltip":""}})}),(_vm.buttonType.length != 0)?_c('el-table-column',{attrs:{"align":_vm.orientation,"fixed":"right","label":"操作","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',_vm._l((_vm.buttonType),function(item){return _c('span',{key:item.name},[(
                item.code == 'acceptance' &&
                  scope.row.handleStatusDesc == '待处理'
              )?_c('el-button',{attrs:{"size":item.size,"type":item.type},on:{"click":function($event){return _vm.buttonClick(scope, item.code)}}},[_vm._v(_vm._s(item.name))]):_vm._e(),(
                item.code == 'modify' &&
                  scope.row.handleStatusDesc == '处理中'
              )?_c('el-button',{attrs:{"size":item.size,"type":item.type},on:{"click":function($event){return _vm.buttonClick(scope, item.code)}}},[_vm._v(_vm._s(item.name))]):_vm._e(),(
                item.code == 'details' &&
                  scope.row.handleStatusDesc == '已完成'
              )?_c('el-button',{attrs:{"size":item.size,"type":item.type},on:{"click":function($event){return _vm.buttonClick(scope, item.code)}}},[_vm._v(_vm._s(item.name))]):_vm._e()],1)}),0)]}}],null,false,3027811300)}):_vm._e()],2),(!_vm.page)?_c('div',{staticClass:"pageBox"},[_c('el-pagination',{attrs:{"background":true,"current-page":_vm.currentValue,"layout":"prev, pager, next","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }