<template>
  <el-dialog
    :show-close="false"
    :visible="dialogVisible"
    top="8vh"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div slot="title" class="header-title">
      <span class="icon-box"></span>
      <span style="color: #000;font-size:16px">瑕疵确认详情页</span>
      <el-divider />
    </div>
    <div v-loading="dialogLoading">
      <el-row style="padding: 0 25px">
        <el-row :gutter="20" style="margin-bottom: 18px">
          <el-col :span="10" style="display: flex">
            <span class="description">衣物名称：</span>
            <el-input v-model="detailedData.clothesName" disabled />
          </el-col>
          <el-col :span="10" style="display: flex">
            <span class="description">衣物号：</span>
            <el-input v-model="detailedData.outClothesNum" disabled />
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 18px">
          <el-col :span="10" style="display: flex">
            <span class="description">瑕疵性质：</span>
            <el-input v-model="detailedData.flawedNature" disabled />
          </el-col>
          <el-col :span="10" style="display: flex">
            <span class="description">瑕疵类型：</span>
            <el-input v-model="detailedData.flawedTypeDesc" disabled />
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 20px">
          <el-col :span="20" style="display: flex">
            <span class="description" style="width: 86px">瑕疵说明：</span>
            <el-input
              v-model="detailedData.factoryExplain"
              class="feedback-content"
              disabled
              :rows="4"
              resize="none"
              type="textarea"
            />
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 5px">
          <el-row style="margin-bottom: 18px; display: flex">
            <span class="description" style="width: 80px;">图片：</span>
            <el-image
              v-for="(item, index) in detailedData.factoryPhotos"
              v-show="index < 3"
              :key="index"
              :preview-src-list="detailedData.factoryPhotos"
              :src="item"
              style="width: 40px; height: 40px; padding-right: 5px"
            />
            <span
              v-if="
                detailedData.factoryPhotos
                  ? detailedData.factoryPhotos.length > 3
                  : false
              "
              class="imgs"
            >
              +{{ detailedData.factoryPhotos.length }}
            </span>
          </el-row>
        </el-row>
      </el-row>
      <el-divider />
      <!-- 记录 -->
      <div class="footer">
        <div class="footer-header">店长回复：</div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="是否同意" prop="repairType">
            <el-select
              v-model="ruleForm.repairType"
              placeholder="请选择是否同意洗"
              :disabled="progress != 1"
            >
              <el-option
                v-for="item in whether"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="回复说明" prop="storeExplain">
            <el-input
              disabled
              type="textarea"
              maxlength="200"
              show-word-limit
              :rows="4"
              v-model="ruleForm.storeExplain"
              style="width:400px"
              placeholder="请输入回复说明"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              v-if="progress != 3"
              type="primary"
              :loading="confirmLoading"
              @click="submitForm('ruleForm')"
              :class="[progress == 1 ? 'confirm' : 'modify']"
              >{{ buttonTypeface }}</el-button
            >
            <el-button @click="handleClose" class="back">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <div class="reminder">
          <span class="verticalLine"></span>
          <div style="padding-right: 10px">
            <span class="exclamatoryMark">
              <i class="el-icon-warning"></i>
            </span>
          </div>
          <div class="reminder-item">
            <span style="color: #ff8800;font-size: 16px;">温馨提示：</span>
            <span v-if="progress == 1" style="font-size: 14px; color: #000">
              请尽快处理， 如未处理此衣物将在 {{ countDown(endDate) }}
              后自动判定退洗，将随物流退回门店。
            </span>
            <span v-if="progress == 2" style="font-size: 14px; color: #000">
              请你谨慎操作！当该催单的状态变更为【已完成】后将无法再修改结果。
            </span>
            <span v-if="progress == 3" style="font-size: 14px; color: #000">
              当前衣物瑕疵确认【已完成】，无法做任意修改。
            </span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :show-close="false"
      :visible="dialoGmodify"
      top="30vh"
      width="600px"
      :before-close="handleClose"
      :close-on-click-modal="true"
      :append-to-body="true"
    >
      <div slot="title" class="header-title">
        <span class="icon-box"></span>
        <span style="color: #000;font-size:16px">修改结果</span>
        <el-divider />
      </div>
      <div class="footer">
        <el-form
          :model="modificationResults"
          :rules="rules"
          ref="modificationResults"
          label-width="150px"
          class="demo-ruleForm"
        >
          <el-form-item label="是否同意" prop="repairType">
            <el-select
              v-model="modificationResults.repairType"
              placeholder="请选择是否同意洗"
            >
              <el-option
                v-for="item in whether"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="回复说明" prop="storeExplain">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="200"
              show-word-limit
              disabled
              v-model="modificationResults.storeExplain"
              style="width:400px"
              placeholder="请输入回复说明"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              v-if="progress != 3"
              :loading="modifyLoading"
              type="primary"
              @click="confirmModification('modificationResults')"
              class="confirm"
              >确认修改</el-button
            >
            <el-button @click="butttonBack" class="back">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { modifyDefects } from "@/api/factory/factoryConfirm";
export default {
  name: "",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    detailedData: {
      type: Object,
      default: () => {},
    },
    dialogLoading: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: String,
      default: "",
    },
    buttonTypeface: {
      type: String,
      default: "",
    },
    confirmLoading:{
      type:Boolean,
      default: false
    }
  },
  data() {
    return {
      dialoGmodify: false,
      ruleForm: {
        flawConfirmId: "",
        repairType: "",
        storeExplain: "同处理意见",
      },
      whether: [
        {
          label: "同意洗",
          value: "1",
        },
        {
          label: "拒绝洗",
          value: "2",
        },
      ],
      rules: {
        repairType: [
          { required: true, message: "请选择是否同意洗", trigger: "blur" },
        ],
        storeExplain: [
          { required: true, message: "请输入回复说明", trigger: "change" },
          {
            pattern: /^[^\s]+[\s]*.*$/,
            trigger: "blur",
            message: "不能只输入空格",
          },
        ],
      },
      modificationResults: {
        flawConfirmId: "",
        repairType: "",
        storeExplain: "",
      },
      countdown: "",
      now: this.$moment(),
      endDate: "",
      modifyLoading: false,
    };
  },
  created() {},
  computed: {
    //计算属性，返回剩余时间
    countDown() {
      return function(endDate) {
        let m1 = this.now;
        let m2 = this.$moment(endDate);
        var du = this.$moment.duration(m2 - m1, "ms"),
          hours = du.get("hours"),
          mins = du.get("minutes"),
          ss = du.get("seconds");
        if (hours <= 0 && mins <= 0 && ss <= 0) {
          return "00:00:00";
        } else {
          return (
            this.PrefixInteger(hours, 2) +
            ":" +
            this.PrefixInteger(mins, 2) +
            ":" +
            this.PrefixInteger(ss, 2)
          );
        }
      };
    },
  },
  watch: {
    dialogVisible() {
      if (this.$refs.ruleForm != undefined) {
        this.$refs.ruleForm.resetFields();
      }
    },
    detailedData(val) {
      console.log(val, "传得数据", val.repairTypeDesc);
      if (this.progress != 1) {
        this.ruleForm.repairType = val.repairTypeDesc;
        this.ruleForm.storeExplain = val.storeExplain;
      }
      this.endTime(val.createTime);
    },
  },
  mounted() {
    setInterval(() => {
      this.now = this.$moment();
    }, 1000);
  },

  methods: {
    handleClose() {
      this.$emit("closeDialog");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.progress == 1) {
            this.ruleForm.flawConfirmId = this.detailedData.flawConfirmId;
            console.log("确认", this.ruleForm);
            this.$emit("acceptance", this.ruleForm);
          } else {
            console.log("修改结果");
            this.dialoGmodify = true;
            this.resetModification();
          }
        } else {
          return false;
        }
      });
    },
    confirmModification(val) {
      this.$refs[val].validate((valid) => {
        if (valid) {
          this.modificationResults.flawConfirmId = this.detailedData.flawConfirmId;
          console.log(this.modificationResults,'确认修改')
          this.modifyLoading = true;
          modifyDefects(this.modificationResults).then((res) => {
            if (res.data.code == 0) {
              this.dialoGmodify = false;
              this.modifyLoading = false;
              this.$message.success(res.data.msg);
              this.$emit("refresh", this.modificationResults.flawConfirmId);
            } else {
              this.modifyLoading = false;
              this.$message.error(res.data.msg);
            }
          }).catch(() => {
             this.modifyLoading = false;
          })
        } else {
          return false;
        }
      });
    },
    resetModification() {
      this.modificationResults = {
        flawConfirmId: "",
        repairType: "",
        storeExplain: "同处理意见",
      };
      if (this.$refs.modificationResults != undefined) {
        this.$refs.modificationResults.resetFields();
      }
    },
    butttonBack() {
      this.dialoGmodify = false;
    },
    PrefixInteger(num, n) {
      return (Array(n).join(0) + num).slice(-n);
    },
    endTime(val) {
      let time = val;
      //  let time = this.detailedData.createTime
      let date = new Date(time);
      this.endDate = new Date(date.getTime() + 24 * 60 * 60 * 1000);
      console.log(this.endDate, "结束时间");
    },
  },
};
</script>

<style scoped lang="scss">
.confirm {
  width: 140px;
  background: #074973;
  color: #fff;
  border: 1px solid #074973;
}
.modify {
  width: 140px;
  background: #860404;
  color: #fff;
  border: 1px solid #860404;
}
.back {
  width: 140px;
  background: #797676;
  color: #fff;
  border: 1px solid #797676;
}
.icon-box {
  display: inline-block;
  background: #3370FF;
  width: 4px;
  height: 18px;
  border-radius: 2px;
  vertical-align: bottom;
  margin-right: 5px;
}
.description {
  text-align: right;
  width: 105px;
  line-height: 32px;
}
::v-deep .el-dialog__body {
  padding: 0px 0px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
::v-deep .el-form--inline .el-form-item__label {
  padding-left: 10px;
}
.imgs {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #e8e8e8;
  cursor: pointer;
}
.icon {
  margin-left: -17px;
  padding: 0 10px;
  font-size: 16px;
}
.footer {
  overflow: auto;
  .footer-header {
    padding: 10px 30px 15px 30px;
    color: #000;
    font-size: 18px;
  }
  .trackProgress {
    font-size: 14px;
  }
}
::v-deep .el-divider--horizontal {
  margin: 15px 0 10px 0;
}
.reminder {
  display: flex;
  flex-direction: row;
  background: #fff3e5;

  .exclamatoryMark {
    display: flex;
    text-align: center;
    padding: 20px 0 0 15px;
    border-radius: 50%;
    i {
      font-size: 25px;
      color: rgb(255, 136, 0);
      position: relative;
    }
  }
  .reminder-item {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }
  .verticalLine {
    display: inline-block;
    background: #ff8800;
    width: 3px;
    height: 60px;
    border-radius: 2px;
    vertical-align: bottom;
    margin-right: 5px;
  }
}
</style>
