<template>
    <div class="reminders content-index">
        <div class="header">
            <div class="reminders-top">
                <el-page-header
                        style="width: 100%;padding:10px"
                        @back="$router.go(-1)"
                        content="确认瑕疵订单"
                ></el-page-header>
                <div class="query-item">
                    <el-select
                            style="width: 240px;margin-right: 10px"
                            v-model="pageData.flawedNature"
                            placeholder="请选择风险/瑕疵类型"
                    >
                        <el-option
                                v-for="item in flawedNatureList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                    <el-input
                            v-model.trim="pageData.outClothesNum"
                            style="width: 240px;"
                            clearable
                            placeholder="请输入衣物号"
                    />
                    <el-button
                            type="primary"
                            style="margin-left:10px"
                            @click="queryClothingNumber"
                    >查询
                    </el-button
                    >
                </div>
            </div>
        </div>
        <el-tabs
                v-model="stateValue"
                type="card"
                @tab-click="handleClick"
                v-loading="loading"
        >
            <el-tab-pane
                    :label="item.label"
                    :name="item.name"
                    v-for="(item, index) in tabPane"
                    :key="index"
            >
                <div slot="label">
                    <el-badge
                            :value="item.notViewed"
                            class="item"
                            :max="99"
                            v-if="item.notViewed > 0"
                    >
                        <span>{{ item.label }}</span>
                        <span v-if="item.name == 6 && item.quantity >= 99">（99+）</span>
                        <span v-else-if="item.quantity != ''">（{{ item.quantity }}）</span>
                    </el-badge>
                    <div v-else>
                        <span>{{ item.label }}</span>
                        <span v-if="item.name == 6 && item.quantity >= 99">（99+）</span>
                        <span v-else-if="item.quantity != ''">（{{ item.quantity }}）</span>
                    </div>
                </div>
            </el-tab-pane>
            <Table
                    :border-bool="true"
                    :button-type="buttonList"
                    :current-value="pageData.current"
                    :header-style="headerStyle"
                    :orientation="'center'"
                    :serial-number="true"
                    :table-data="tableData"
                    :title-list="titleListData"
                    :total="total"
                    :height="'58vh'"
                    @operationClick="operationClick"
                    @handleCurrentChange="handleCurrentChange"
            />
        </el-tabs>
        <Details
                :dialogVisible="dialogVisible"
                :detailedData="detailedData"
                :progress="progress"
                :dialogLoading="dialogLoading"
                :confirmLoading="confirmLoading"
                :buttonTypeface="buttonTypeface"
                @closeDialog="closeDialog"
                @acceptance="acceptance"
                @refresh="refresh"
        />
    </div>
</template>

<script>
import Table from "./component/table";
import Details from "./component/details";
import {
    selectPageByStatusAndStoreId,
    flawStatus,
    selectOperationFirmById,
    modifyDefects,
} from "@/api/factory/factoryConfirm";
import {queryDictItemByDictName} from "@/api/todayData";

export default {
    name: "complaint",
    components: {
        Table,
        Details,
    },
    data() {
        return {
            total: 0,
            pageData: {
                current: 1,
                size: 10,
                outClothesNum: "",
                flawedNature: '',
                queryFlag: "1",
            },
            stateValue: "1",
            detailedData: {},
            tableData: [],
            titleListData: [
                {
                    prop: "flawedNatureDesc",
                    label: "风险/瑕疵性质",
                    width: "180",
                },
                {
                    prop: "flawedTypeDesc",
                    label: "风险/瑕疵类型",
                    width: "180",
                },
                {
                    prop: "storeName",
                    label: "门店",
                    width: "150",
                },
                {
                    prop: "outClothesNum",
                    label: "衣物号",
                    width: "150",
                },
                {
                    prop: "clothesName",
                    label: "衣物名称",
                    width: "150",
                },
                {
                    prop: "washWay",
                    label: "服务",
                    width: "150",
                },
                {
                    prop: "createTime",
                    label: "提交时间",
                    width: "180",
                },
                {
                    prop: "repairTypeDesc",
                    label: "门店回复",
                    width: "200",
                },
            ],
            tabPane: [
                {
                    label: "待处理",
                    name: "1",
                    notViewed: "",
                    quantity: "",
                },
                {
                    label: "处理中",
                    name: "2",
                    notViewed: "",
                    quantity: "",
                },
                {
                    label: "同意洗",
                    name: "3",
                    notViewed: "",
                    quantity: "",
                },
                {
                    label: "拒绝洗",
                    name: "4",
                    notViewed: "",
                    quantity: "",
                },
                {
                    label: "已完成",
                    name: "6",
                    notViewed: "",
                    quantity: "",
                },
            ],
            buttonList: [
                {
                    name: "受理",
                    size: "medium",
                    type: "text",
                    code: "acceptance",
                },
                {
                    name: "修改结果",
                    size: "medium",
                    type: "text",
                    code: "modify",
                },
                {
                    name: "进入详情",
                    size: "medium",
                    type: "text",
                    code: "details",
                },
            ],
            headerStyle: {
                background: "#FFF",
            },
            title: "",
            dialogVisible: false,
            dialogDetails: false,
            dialogLoading: false,
            loading: false,
            progress: "",
            buttonTypeface: "",
            confirmLoading: false,
            flawedNatureList: []
        };
    },
    created() {
        this.getFlawedNatures()
        this.selectPageByStatusAndStoreId();
    },
    methods: {
        // 获取门店返洗类型
        getFlawedNatures() {
            queryDictItemByDictName("瑕疵性质").then((response) => {
                this.flawedNatureList = response.data.data.map((item) => {
                    return {
                        value: item.itemValue,
                        label: item.itemText,
                    };
                });
            });
        },
        // tabs切换
        handleClick(val) {
            this.pageData.outClothesNum = "";
            this.pageData.queryFlag = val.name;
            this.pageData.current = 1;
            this.selectPageByStatusAndStoreId();
        },
        // 分页
        handleCurrentChange(value) {
            this.pageData.current = value;
            this.selectPageByStatusAndStoreId();
        },
        //查询瑕疵列表
        selectPageByStatusAndStoreId() {
            this.loading = true;
            selectPageByStatusAndStoreId(this.pageData).then((res) => {
                console.log(res, "瑕疵列表");
                this.tableData = res.data.data.records;
                this.total = res.data.data.total;
                this.loading = false;
                this.flawStatus();
            });
        },
        queryClothingNumber() {
            this.selectPageByStatusAndStoreId();
        },
        //表格按钮
        operationClick({row, code}) {
            this.dialogVisible = true;
            this.dialogLoading = true;
            this.selectOperationFirmById(row.flawConfirmId);
            if (row.storeViewStatus == 0) {
                this.selectPageByStatusAndStoreId();
            }
            if (code == "acceptance") {
                this.progress = "1";
                this.buttonTypeface = "确认";
            } else if (code == "modify") {
                this.progress = "2";
                this.buttonTypeface = "修改结果";
            } else {
                this.progress = "3";
            }
        },
        //查询详情数据
        selectOperationFirmById(id) {
            selectOperationFirmById(id)
                .then((res) => {
                    if (res.data.code == 0) {
                        let list = res.data.data;
                        if (list.flawedNature == 1) {
                            list.flawedNature = "一般瑕疵";
                        } else if (list.flawedNature == 2) {
                            list.flawedNature = "严重瑕疵";
                        } else {
                            list.flawedNature = "洗后风险告知";
                        }
                        this.detailedData = list;
                        this.dialogLoading = false;
                    } else {
                        this.dialogLoading = false;
                    }
                })
                .catch(() => {
                    this.dialogLoading = false;
                });
        },
        //受理
        acceptance(val) {
            this.confirmLoading = true;
            modifyDefects(val)
                .then((res) => {
                    if (res.data.code == 0) {
                        this.dialogVisible = false;
                        this.confirmLoading = false;
                        this.$message.success(res.data.msg);
                        this.selectPageByStatusAndStoreId();
                    } else {
                        this.confirmLoading = false;
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(() => {
                    this.confirmLoading = false;
                });
        },
        //修改结果刷新
        refresh(val) {
            this.selectOperationFirmById(val);
            this.selectPageByStatusAndStoreId();
        },
        //查询瑕疵状态
        flawStatus() {
            flawStatus().then((res) => {
                console.log(res, "瑕疵状态");
                let data = res.data.data;
                this.tabPane = this.tabPane.map((item, index) => {
                    if (index == 0 && data.pendingCount != 0) {
                        item.quantity = data.pendingCount;
                        item.notViewed = data.pendingNoViewCount;
                    } else if (index == 1 && data.processingCount != 0) {
                        item.quantity = data.processingCount;
                        item.notViewed = data.processingNoViewCount;
                    } else if (index == 2 && data.agreeCount != 0) {
                        item.quantity = data.agreeCount;
                        item.notViewed = data.agreeNoViewCount;
                    } else if (index == 3 && data.refuseCount != 0) {
                        item.quantity = data.refuseCount;
                        item.notViewed = data.refuseNoViewCount;
                    } else if (index == 4 && data.processedCount != 0) {
                        item.quantity = data.processedCount;
                        item.notViewed = data.processedNoViewCount;
                    } else {
                        item.quantity = "0";
                        item.notViewed = "";
                    }
                    return item;
                });
            });
        },
        //窗口关闭
        closeDialog(val) {
            this.dialogVisible = false;
        },
    },
};
</script>

<style scoped lang="scss">
.reminders {
  overflow: auto;

  .header {
    padding: 17.5px 20px;
    overflow: hidden;

    > .reminders-top {
      float: left;
      color: #333;
      text-align: left;
      font-size: 20px;
      font-family: PingFang-SC-Bold;

      .query-item {
        display: flex;
        padding: 10px 0;
      }
    }

    > .reminder-item {
      display: flex;
      border-radius: 5px;
      background: #b30d0d;
      border-color: #b30d0d;
      float: right;
    }

    .btn-add {
      background: #b30d0d;
      border-color: #b30d0d;
      float: right;
    }
  }
}

.icon-box {
  position: absolute;
  width: 3px;
  height: 22px;
  left: 24px;
  background: #3894ff;
}

.el-tabs {
  margin: 0px 20px 0;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #e4e7ed;
}

::v-deep .el-badge__content.is-fixed {
  top: 10px;
  right: 10px;
}

::v-deep .el-tabs__item {
  padding: 0 30px !important;
}

::v-deep
.el-tabs--top.el-tabs--card
> .el-tabs__header
.el-tabs__item:last-child {
  padding: 0 30px;
}
</style>
